<template>
  <div class="pedido-listado">
    <section class="pedido-list">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="return d-block">
              <router-link :to="route + 'contacto'">
                <img src="assets/img/backspace.svg" class="mb-5">
              </router-link>
            </div>
            <div class="section-title">
              <h5 class="pull-left">{{ $t("text") }}</h5>
            </div>
          </div>
        </div>

        <!-- Filtros de búsqueda -->
        <div class="row mb-4">
          <div class="col-md-3">
            <label for="startDate">{{ $t('name') }}</label>
            <input type="text" v-model="filters.name" class="form-control">
          </div>
          <div class="col-md-3">
            <label for="startDate">{{ $t('email') }}</label>
            <input type="text" v-model="filters.email" class="form-control">
          </div>
          <div class="col-md-3">
            <label for="startDate">{{ $t('po_number') }}</label>
            <input type="text" v-model="filters.poNumber" class="form-control">
          </div>
          <div class="col-md-3">
            <label for="startDate">{{ $t('invoice_number') }}</label>
            <input type="text" v-model="filters.invoiceNumber" class="form-control">
          </div>
        </div>

        <!-- Filtro de fecha -->
        <div class="row mb-4">
          <div class="col-md-6">
            <label for="startDate">{{ $t('start_date') }}</label>
            <input type="date" id="startDate" v-model="filters.startDate" class="form-control">
          </div>
          <div class="col-md-6">
            <label for="endDate">{{ $t('end_date') }}</label>
            <input type="date" id="endDate" v-model="filters.endDate" class="form-control">
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <button @click="clearFilters" class="btn btn-secondary">
              {{ $t('clear_filters') }}
            </button>
          </div>
        </div>

        <div class="row" id="mostrarTablas">
          <div class="col-md-12">
            <div class="confirmado mt-2">
              <!--Tabla Desktop-->
              <table class="bg-white d-none d-sm-table" id="pedidosTable">
                <thead>
                  <tr>
                    <th>{{ $t("date") }}</th>
                    <th class="text-center">{{ $t("name") }}</th>
                    <th class="text-center">{{ $t("email") }}</th>
                    <th class="text-center">{{ $t("request") }}</th>
                    <th class="text-center">{{ $t("reason") }}</th>
                    <th class="text-center">{{ $t("po_number") }}</th>
                    <th class="text-center">{{ $t("invoice_number") }}</th>
                    <th class="text-center">{{ $t("description") }}</th>
                    <th class="text-center">{{ $t("status") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="b-top" v-for="(caso,index) in paginatedCases" :key="index">
                    <td>{{ formatDate(caso.CreatedDate) }}</td>
                    <td class="text-center">{{ caso.SuppliedName }}</td>
                    <td class="text-center">{{ caso.SuppliedEmail }}</td>
                    <td class="text-center">{{ caso.Request__c }}</td>
                    <td class="text-center">{{ caso.Reason__c }}</td>
                    <td class="text-center">{{ caso.PO_Number__c }}</td>
                    <td class="text-center">{{ caso.Invoice_Number__c }}</td>
                    <td class="text-center">{{ caso.Description }}</td>
                    <td class="text-center">
                      <div class="inline-flex align-center label-content justify-center">
                        <a href="javascript:void(0)" :class="'label-order-change-' + caso.Status">{{ caso.Status }}</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Tabla para móvil -->
              <table class="bg-white d-block d-sm-none pedidosTableMobile b-top" v-for="(order_change,index) in paginatedCases" :key="index">
                <tr>
                  <th>{{ $t("date") }}</th>
                  <td>{{ formatDate(order_change.CreatedDate) }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t("po_number") }}</th>
                  <td class="text-center">{{ order_change.PO_Number__c }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t("request") }}</th>
                  <td class="text-center">{{ order_change.Request__c }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t("description") }}</th>
                  <td class="text-center">{{ order_change.Description }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t("status") }}</th>
                  <td class="text-center">
                    <div class="inline-flex align-center label-content justify-center">
                      <a href="javascript:void(0)" :class="'label-order-change-' + order_change.Status">{{ order_change.Status   }}</a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div v-if="isLoading" class="spinner"></div>

        <!-- Paginación -->
        <div class="row">
          <div class="col-md-12 text-center mb-5">
            <ul class="pagination">
              <li @click="changePage(currentPage - 1)" :class="{'disabled': currentPage === 1}">
                <span class="pagination-arrow">&#8592;</span>
              </li>

              <li @click="changePage(index)" v-for="index in totalPages" :key="index" :class="{'active': index === currentPage}">
                <span>{{ index }}</span>
              </li>

              <li @click="changePage(currentPage + 1)" :class="{'disabled': currentPage === totalPages}">
                <span class="pagination-arrow">&#8594;</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert"/>
  </div>
</template>

<script>
import { route } from "../../enviroment.js";
import axiosInstance from "@/main";
import authMixin from "@/mixins/authMixin";

export default {
  name: 'MisCasos',
  data() {
    return {
      cases: [],
      filters: {
        name: '',
        email: '',
        poNumber: '',
        invoiceNumber: '',
        startDate: '',
        endDate: '',
      },
      route: route,
      currentPage: 1,
      casesPerPage: 10,
      isLoading: false,
    };
  },
  mixins: [authMixin],
  i18n: {
    messages: {
      en: {
        text: "List of cases.",
        date: "Date",
        reason: "Reason",
        request: "Request",
        description: "Description",
        status: "Status",
        email: "Email",
        name: "Name",
        invoice_number: "Invoice Number",
        po_number: "PO Number",
        start_date: "Start Date",
        end_date: "End Date",
        clear_filters: "Clear filters"
      },
      es: {
        text: "Listado de los casos creados.",
        date: "Fecha",
        description: "Descripcion",
        status: "Estado",
        reason: "Motivo",
        request: "Solicitud",
        email: "Email",
        name: "Nombre",
        invoice_number: "Numero de Factura",
        po_number: "Numero PO",
        start_date: "Fecha de inicio",
        end_date: "Fecha de Fin",
        clear_filters: "Limpiar filtros"
      }
    }
  },
  computed: {
    filteredCases() {
      return this.cases.filter(caso => {
        const caseDate = new Date(caso.CreatedDate);
        const startDate = this.filters.startDate ? new Date(this.filters.startDate) : null;
        const endDate = this.filters.endDate ? new Date(this.filters.endDate) : null;

        const dateInRange = (!startDate || caseDate >= startDate) && (!endDate || caseDate <= endDate);

        return (
          (!this.filters.name || (this.filters.name && caso.SuppliedName && caso.SuppliedName.toLowerCase().includes(this.filters.name.toLowerCase()))) &&
          (!this.filters.email || (this.filters.email && caso.SuppliedEmail && caso.SuppliedEmail.toLowerCase().includes(this.filters.email.toLowerCase()))) &&
          (!this.filters.poNumber || (this.filters.poNumber && caso.PO_Number__c && caso.PO_Number__c.toLowerCase().includes(this.filters.poNumber.toLowerCase()))) &&
          (!this.filters.invoiceNumber || (this.filters.invoiceNumber && caso.Invoice_Number__c && caso.Invoice_Number__c.toLowerCase().includes(this.filters.invoiceNumber.toLowerCase()))) &&
          dateInRange
        );
      });
    },
    paginatedCases() {
      const start = (this.currentPage - 1) * this.casesPerPage;
      const end = start + this.casesPerPage;
      return this.filteredCases.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredCases.length / this.casesPerPage);
    },
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    async getCases() {
      try {
        this.isLoading = true;
        const response = await axiosInstance.get(`${this.$woe_api_uri}/contact/cases`);
        if (Array.isArray(response.data.data.cases.records)) {
          this.cases = response.data.data.cases.records.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate));
          console.log('this.cases',this.cases);
        } else {
          console.error("La respuesta no contiene un array de casos válido:", response.data);
          this.showAlert('error', 'Error', 'La respuesta del servidor no contiene un array de casos válido.');
        }
        this.isLoading = false;
      } catch (error) {
        this.showAlert('warning', `${this.$t("caution")}!`, error.response?.data?.error?.message || error.message);
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    clearFilters() {
      this.filters = {
        name: '',
        email: '',
        poNumber: '',
        invoiceNumber: '',
        startDate: '',
        endDate: ''
      };
    },
  },
  async created() {
    const valid = await this.validateUserAndSoldTo();
    if (valid) {
      this.getCases();
    }
  },
};
</script>

<style>
.pagination {
  display: inline-flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.pagination li {
  margin: 0 10px;
  cursor: pointer;
  font-size: 18px;
}

.pagination li span {
  padding: 5px 10px;
  border-radius: 5px;
}

.pagination li.active span {
  background-color: #ffd968;
  color: black;
}

.pagination li.disabled span {
  color: #ccc;
  cursor: not-allowed;
}

.pagination-arrow {
  font-size: 20px;
}

.pagination li:hover span {
  background-color: #e7e7e7;
}
</style>